export const panicAtackCommentsData = [
  {
    img: 'person4.svg',
    imgAlt: 'Портрет усатого мужчины с черными волосами на красном фоне',
    name: 'Сергей',
    age: '32 года',
    title: 'Я чувствую, что сделал верный выбор',
    desc: 'Сначала было страшно обращаться за помощью, но я не пожалел об этом. Я чувствую, что сделал верный выбор.'
  },
  {
    img: 'person0.svg',
    imgAlt: 'Портрет девушки с розовыми волосами на зеленом фоне',
    name: 'Любовь',
    age: '23 года',
    title: 'Поняла, что больше не могу откладывать',
    desc: 'В течение долгого времени я не могла решиться на сессию с психологом, потому что боялась, что не найду подходящего специалиста. Недавно я поняла, что больше не могу откладывать, и начала искать психолога на различных онлайн-платформах, где мне предлагали специалистов по разным критериям. На платформе YouTalk мне понравился психолог, и я записалась на первую сессию. Специалист выслушала меня и помогла разобраться в моих вопросах. Обязательно продолжу терапию.'
  },
  {
    img: 'person10.svg',
    imgAlt:
      'Портрет темноволосового кудрявого мужчины с усами и в очках на светло-оранжевом фоне',
    name: 'Арсений',
    age: '36 лет',
    title: 'Сильно улучшились мои навыки борьбы с паническими атаками',
    desc: 'После занятий с психологом сильно улучшились мои навыки борьбы с паническими атаками'
  },
  {
    img: 'person7.svg',
    imgAlt: 'Портрет темноволосой девушки на желтом фоне',
    name: 'Лиза',
    age: '24 года',
    title: 'Спасибо сервису за удобство записи на консультацию',
    desc: 'Спасибо сервису за удобство записи на консультацию! Чувствовала себя плохо, и очень хотелось разобрать ситуацию с психологом. Консультант быстро мне помог найти психолога и записаться!'
  },
  {
    img: 'person12.svg',
    imgAlt: 'Портрет рыжеволосого молодого человека на синем фоне',
    name: 'Илья',
    age: '30 лет',
    title: 'Я нуждался в поддержке опытного психолога',
    desc: 'После тяжелого периода в жизни я нуждался в поддержке опытного психолога. На сайте я нашел специалиста с большим стажем работы. Система бронирования удобная, все четко и понятно 👍🏻'
  },
  {
    img: 'person1.svg',
    imgAlt: 'Портрет девушки в очках с черными волосами на красном фоне',
    name: 'Алла',
    age: '33 года',
    title: 'Мое состояние улучшается в разы',
    desc: 'Кажется, что мой психолог знает сотни техник)) Мне очень нравится, что мы делаем много практических заданий после которых мое состояние улучшается в разы.'
  }
]
