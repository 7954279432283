import React from 'react'
import styled from 'styled-components'
import { Button, size } from '../../../youtalk-storybook/src/ui'

const PanicAttackButton = styled(({ className }) => (
  <div className={className}>
    <Button.NewPrimary href="/wizard/" size="large" type="link">
      Подобрать психолога
    </Button.NewPrimary>
  </div>
))`
  display: none;
  background: white;
  max-width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  position: relative;

  @media ((max-width: ${size.sm})) {
    display: flex;
    padding-top: 16px;
    padding-bottom: 46px;
  }

  @media ((max-width: ${size.xs})) {
    padding-top: 24px;
    padding-bottom: 40px;
  }

  ${Button.NewPrimary} {
    width: 100%;
  }
`

export default PanicAttackButton
